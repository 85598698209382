<template>
  <div>
    <div v-show="showInformations" class="wrapper">
      <div class="wrapper__card">
        <div class="wrapper__card__header">
          <div class="left">
            <span>Consultar conexões</span>
          </div>
          <div class="right">
            <div>
              <z-input
                v-model="searchConnection"
                placeholder="Pesquisar"
                prependIcon="$search_rounded"
                type="text"
                :hideDetails="true"
              />
            </div>
            <div>
              <z-btn small rounded text="Filtrar" icon="mdi-filter-outline" />
            </div>
            <div>
              <z-btn
                rounded
                primary
                text="Nova conexão"
                icon="mdi-plus"
                small
                @click="goToPage('createConversionConnectionPage')"
              />
            </div>
            <div>
              <z-btn
                @click="goToPage('createConversionConnectionPage')"
                small
                rounded
                text="ir para audiência"
                icon="mdi-arrow-collapse-right"
                :iconRight="true"
              />
            </div>
          </div>
        </div>
        <div class="wrapper__card__body">
          <z-pagination
            auto-pagination
            icon-previous="mdi-chevron-left"
            icon-next="mdi-chevron-right"
            :items-per-page="15"
            :number-of-items="searchConnection ? filtered.length : recent.lenth"
            :items="searchConnection ? filtered : recent"
          >
            <template #data="{ paginatedItems }">
              <div class="table-wrapper">
                <z-table
                  class="table"
                  :headers="headers"
                  :items="paginatedItems"
                  icon-next="mdi-chevron-right"
                  icon-previous="mdi-chevron-left"
                  @sort="sortTable"
                >
                  <template #no-data>
                    <div class="no-data-table">
                      <h3>Sem dados disponíveis.</h3>
                    </div>
                  </template>
                  <template v-slot:[`item.favorite`]="{ item }">
                    <div class="d-flex align-center justify-center">
                      <img
                        v-if="item.favorite"
                        :style="iconStyle"
                        src="/icons/favorite-icon.svg"
                        class="mr-1"
                        @click="favorite(item)"
                      />
                      <img
                        v-else
                        :style="iconStyle"
                        src="/icons/favorite-not-filled-icon.svg"
                        class="mr-1"
                        @click="favorite(item)"
                      />
                    </div>
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    <span>
                      {{ item.name || "Sem registro" }}
                    </span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions">
                      <v-tooltip bottom color="grey">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-1"
                            @click="selectOptionFromTable(item, 4)"
                          >
                            $edit_rounded</v-icon
                          >
                        </template>
                        <span style="color: white; padding: 0.5rem; font-weight: 500"
                          >Editar</span
                        >
                      </v-tooltip>
                      <v-tooltip bottom color="grey">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-1"
                            @click="selectOptionFromTable(item, 1)"
                            >$edit_outline</v-icon
                          >
                        </template>
                        <span style="color: white; padding: 0.5rem; font-weight: 500"
                          >Renomear</span
                        >
                      </v-tooltip>

                      <v-tooltip bottom color="grey">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-1"
                            @click="selectOptionFromTable(item, 2)"
                            >$zoox_document</v-icon
                          >
                        </template>
                        <span style="color: white; padding: 0.5rem; font-weight: 500"
                          >Documentação de api</span
                        >
                      </v-tooltip>

                      <v-tooltip bottom color="grey">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-1"
                            @click="selectOptionFromTable(item, 3)"
                            >$clock</v-icon
                          >
                        </template>
                        <span style="color: white; padding: 0.5rem; font-weight: 500"
                          >Logs</span
                        >
                      </v-tooltip>

                      <v-tooltip bottom color="grey">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-1"
                            @click="selectOptionFromTable(item, 0)"
                            >$delete_outline_rounded</v-icon
                          >
                        </template>
                        <span style="color: white; padding: 0.5rem; font-weight: 500"
                          >Apagar</span
                        >
                      </v-tooltip>
                    </div>
                  </template>

                  <template v-slot:[`item.created`]="{ item }">
                    <span>{{ formateDate(item.created) }} </span>
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <span class="badge" :style="setBadgeTheme(item.status || 'error')">
                      {{ stats[item?.status || "error"] }}
                    </span>
                  </template>
                </z-table>
              </div>
            </template>
          </z-pagination>

          <!-- UTILIZAÇÃO COM ACORDIÕES -->
          <!-- <ZAccordion
            icon="mdi-star"
            img-path="/icons/favorite-icon.svg"
            icon-color="#FFC220"
            title="Favoritos"
            :quantity="getFavorites.length"
          >
            <div class="wrapper__card__body__cards">
              <v-row dense>
                <v-col
                  v-for="(item, i) in getFavorites"
                  :key="i"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="4"
                >
                  <z-card :title="item.name" width="100%" :shadow="true" class="item">
                    <template #actions>
                      <div class="d-flex align-center justify-center">
                        <img
                          v-if="item.favorite"
                          :style="iconStyle"
                          src="/icons/favorite-icon.svg"
                          class="mr-1"
                          @click="favorite(item)"
                        />
                        <img
                          v-else
                          :style="iconStyle"
                          src="/icons/favorite-not-filled-icon.svg"
                          class="mr-1"
                          @click="favorite(item)"
                        />
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              v-bind="attrs"
                              v-on="on"
                              :style="iconStyle2"
                              src="/icons/3-dots-icon.svg"
                            />
                          </template>

                          <div v-if="item.apiEnabled" class="list-options">
                            <div
                              v-for="(option, index) in optionsMenu"
                              :key="index"
                              class="list-options__item"
                              @click="selectDotOption(item, option)"
                            >
                              <span>
                                {{ option.text }}
                              </span>
                            </div>
                          </div>
                          <div v-else class="list-options">
                            <div
                              v-for="(option, index) in optionsMenu2"
                              :key="index"
                              class="list-options__item"
                              @click="selectDotOption(item, option)"
                            >
                              <span>
                                {{ option.text }}
                              </span>
                            </div>
                          </div>
                        </v-menu>
                      </div>
                    </template>

                    <template #content>
                      <div style="margin-top: 1rem">
                        <span>Ultima modificação:</span>
                        <br />
                        <div class="d-flex justify-space-between">
                          <span>{{ formateDate(item.updated) }} </span>
                          <span
                            class="badge"
                            :style="setBadgeTheme(item.status || 'error')"
                          >
                            {{ stats[item?.status || "error"] }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </z-card>
                </v-col>
              </v-row>
            </div>
          </ZAccordion>
          <ZAccordion
            icon=""
            icon-color="#FF8F28"
            title="Todos"
            :quantity=" !searchConnection ? recent.length : filtered.length"
          >
            <div class="wrapper__card__body__cards">
             <template v-if="!searchConnection">
              <v-row dense>
                <v-col
                  v-for="(item, i) in recent"
                  :key="i"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="4"
                >
                  <z-card :title="item.name" width="100%" :shadow="true" class="item">
                    <template #actions>
                      <div class="d-flex align-center justify-center">
                        <img
                          v-if="item.favorite"
                          :style="iconStyle"
                          src="/icons/favorite-icon.svg"
                          class="mr-1"
                          @click="favorite(item)"
                        />
                        <img
                          v-else
                          :style="iconStyle"
                          src="/icons/favorite-not-filled-icon.svg"
                          class="mr-1"
                          @click="favorite(item)"
                        />
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              v-bind="attrs"
                              v-on="on"
                              :style="iconStyle2"
                              src="/icons/3-dots-icon.svg"
                            />
                          </template>

                          <div v-if="item.apiEnabled" class="list-options">
                            <div
                              v-for="(option, index) in optionsMenu"
                              :key="index"
                              class="list-options__item"
                              @click="selectDotOption(item, option)"
                            >
                              <span>
                                {{ option.text }}
                              </span>
                            </div>
                          </div>
                          <div v-else class="list-options">
                            <div
                              v-for="(option, index) in optionsMenu2"
                              :key="index"
                              class="list-options__item"
                              @click="selectDotOption(item, option)"
                            >
                              <span>
                                {{ option.text }}
                              </span>
                            </div>
                          </div>
                        </v-menu>
                      </div>
                    </template>

                    <template #content>
                      <div style="margin-top: 1rem">
                        <span>Ultima modificação:</span>
                        <br />
                        <div class="d-flex justify-space-between">
                          <span>{{ formateDate(item.updated) }} </span>
                          <span
                            class="badge"
                            :style="setBadgeTheme(item.status || 'error')"
                          >
                            {{ stats[item?.status || "error"] }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </z-card>
                </v-col>
              </v-row>
             </template>

             <template v-else>
              <v-row dense>
                <v-col
                  v-for="(item, i) in filtered"
                  :key="i"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="4"
                >
                  <z-card :title="item.name" width="100%" :shadow="true" class="item">
                    <template #actions>
                      <div class="d-flex align-center justify-center">
                        <img
                          v-if="item.favorite"
                          :style="iconStyle"
                          src="/icons/favorite-icon.svg"
                          class="mr-1"
                          @click="favorite(item)"
                        />
                        <img
                          v-else
                          :style="iconStyle"
                          src="/icons/favorite-not-filled-icon.svg"
                          class="mr-1"
                          @click="favorite(item)"
                        />
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              v-bind="attrs"
                              v-on="on"
                              :style="iconStyle2"
                              src="/icons/3-dots-icon.svg"
                            />
                          </template>

                          <div v-if="item.apiEnabled" class="list-options">
                            <div
                              v-for="(option, index) in optionsMenu"
                              :key="index"
                              class="list-options__item"
                              @click="selectDotOption(item, option)"
                            >
                              <span>
                                {{ option.text }}
                              </span>
                            </div>
                          </div>
                          <div v-else class="list-options">
                            <div
                              v-for="(option, index) in optionsMenu2"
                              :key="index"
                              class="list-options__item"
                              @click="selectDotOption(item, option)"
                            >
                              <span>
                                {{ option.text }}
                              </span>
                            </div>
                          </div>
                        </v-menu>
                      </div>
                    </template>

                    <template #content>
                      <div style="margin-top: 1rem">
                        <span>Ultima modificação:</span>
                        <br />
                        <div class="d-flex justify-space-between">
                          <span>{{ formateDate(item.updated) }} </span>
                          <span
                            class="badge"
                            :style="setBadgeTheme(item.status || 'error')"
                          >
                            {{ stats[item?.status || "error"] }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </z-card>
                </v-col>
              </v-row>
             </template>
            </div>
          </ZAccordion> -->

          <div class="wrapper__card__body--actions">
            <!-- <z-btn
            text="Voltar"
            color="primary"
            :rounded="true"
            icon="$arrow_back_rounded"
            @click="$router.push('/')"
          /> -->
          </div>
        </div>
      </div>
      <z-card-modal v-model="dialogDelete" width="400" title="">
        <template #content>
          <div class="delete-confirmation">
            <div class="delete-confirmation__title mb-5">
              <span>Tem certeza que deseja apagar a conexão</span>
              <br />
              <strong>{{ connectionToDelete?.name || "Sem nome resgistrado" }}</strong>
            </div>

            <div class="delete-confirmation--actions">
              <z-btn
                small
                rounded
                text="cancelar"
                @click="
                  dialogDelete = false;
                  resetConnectionToDelete();
                "
              />

              <z-btn
                rounded
                primary
                text="apagar"
                small
                :isLoading="loadingDelete"
                @click="confirmedDeleteConnection()"
              />
            </div>
          </div>
        </template>
      </z-card-modal>
      <z-card-modal v-model="dialogRename" width="400" title="">
        <template #content>
          <div v-if="connectionToUpdate?.id" class="delete-confirmation">
            <div class="delete-confirmation__title mb-1">
              <span class="mb-5">Insira o novo nome para a conexão:</span>
              <z-input v-model="connectionToUpdate.name" class="mt-3" />
            </div>

            <div class="delete-confirmation--actions">
              <z-btn
                small
                rounded
                text="cancelar"
                @click="
                  dialogRename = false;
                  resetConnectionToRename();
                "
              />

              <z-btn
                rounded
                primary
                text="Renomear"
                small
                :isLoading="loadingRename"
                @click="confirmUpdateNameConnection()"
              />
            </div>
          </div>
        </template>
      </z-card-modal>
    </div>
    <div v-show="!showInformations" class="wrapper no-data">
      <div class="wrapper__card">
        <!-- <div class="loader"></div> -->
        <z-loading />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ApiClient from "@/services/ApiClient";
// import ZAccordion from "@/components/Shared/ZAccordion.vue";
export default {
  components: {
    // ZAccordion,
  },
  data() {
    return {
      sortDesc: false,
      headers: [
        { text: "", value: "favorite" },
        { text: "Nome", value: "name", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Data de criação", value: "created", sortable: true },
        { text: "Ações", value: "actions" },
      ],
      searchConnection: "",
      connectionToDelete: null,
      connectionToUpdate: null,
      dialogRename: false,
      loadingRename: false,
      loadingDelete: false,
      dialogDelete: false,
      optionsMenu: [
        { action: "delete", text: "Apagar", value: 0 },
        { action: "rename", text: "Renomear", value: 1 },
        { action: "document", text: "Documentação", value: 2 },
        { action: "log", text: "Ver log", value: 3 },
      ],
      optionsMenu2: [
        { action: "delete", text: "Apagar", value: 0 },
        { action: "rename", text: "Renomear", value: 1 },
        { action: "log", text: "Ver log", value: 3 },
      ],
      iconStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
      iconStyle2: {
        width: "23px",
        height: "23px",
        cursor: "pointer",
      },
      showInformations: false,
      fetchIntervalFn: null,
      stats: {
        pending: "Pendente",
        updated: "Atualizado",
        error: "Error",
        "in-progress": "Em progresso",
      },
      http: new ApiClient(),
      recent: [],
      filtered: [],
      cards: [
        "MULHERES DO RIO",
        "SUL E SUDESTE",
        "NORDESTE",
        "FAIXA ETÁRIA ATÉ 35 ANOS",
        "HOMENS DE SÃO PAULO",
        "IDOSOS + 60",
      ],
      currentUrl: null,
    };
  },
  beforeDestroy() {
    if (this.fetchIntervalFn) {
      clearInterval(this.fetchIntervalFn);
    }
  },
  created() {
    this.getConfigurationList();

    this.fetchIntervalFn = setInterval(async () => {
      await this.getConfigurationList();
    }, 5000);
  },
  watch: {
    dialogDelete(nv) {
      if (!nv) this.resetConnectionToDelete();
    },
    searchConnection(nv) {
      this.filtered = this.getConnectionsByName(nv);
    },
  },
  computed: {
    getFavorites() {
      return !this.searchConnection
        ? this.recent.filter((item) => item.favorite == true)
        : this.filtered.filter((item) => item.favorite == true);
    },
  },
  methods: {
    getConnectionsByName(name) {
      return this.recent.filter((item) => item.name.includes(name));
    },
    resetConnectionToDelete() {
      this.connectionToDelete = null;
    },
    selectOptionFromTable(data, option) {
      switch (option) {
        case 0:
          this.deleteConnection(data);
          break;
        case 1:
          this.updateConnection(data);
          break;
        case 2:
          this.$router.push({
            name: "docsIndexPage",
            params: { page: "conversionData", id: data.id },
          });
          break;
        case 3:
          this.$router.push({
            name: "logsIndexPage",
            params: { page: "conversionData", id: data.id },
          });
          break;
        case 4:
          this.$router.push({
            name: "editConversionDataPage",
            params: { id: data.id },
          });
          break;
      }
    },
    selectDotOption(data, optionData) {
      switch (optionData.value) {
        case 0:
          this.deleteConnection(data);
          break;
        case 1:
          this.updateConnection(data);
          break;
        case 2:
          this.$router.push({
            name: "docsIndexPage",
            params: { page: "conversionData", id: data.id },
          });
          break;
        case 3:
          this.$router.push({
            name: "logsIndexPage",
            params: { page: "conversionData", id: data.id },
          });
          break;
      }
    },
    deleteConnection(connection) {
      this.connectionToDelete = connection;
      this.dialogDelete = true;
    },
    updateConnection(connection) {
      this.connectionToUpdate = { ...connection };
      this.dialogRename = true;
    },
    async confirmedDeleteConnection() {
      this.loadingDelete = true;
      try {
        const req = await this.http.delete(
          `dbConfiguration/${this.connectionToDelete.id}`
        );
        if (req) {
          this.getConfigurationList();
          this.$toast.success("Conexão aparaga com sucesso!");
          this.resetConnectionToDelete();
          this.dialogDelete = false;
        }
        this.loadingDelete = false;
      } catch (error) {
        this.$toast.error("Erro ao deletar conexão");
        this.loadingDelete = false;
      }
    },
    setBadgeTheme(item) {
      const DEFAULT = {
        backgroundColor: "#d0294b",
        color: "#ffffff",
      };

      const STYLES = {
        updated: {
          backgroundColor: "#49c469",
          color: "#ffffff",
        },
        pending: {
          backgroundColor: "#FFC220",
          color: "#ffffff",
        },
        "in-progress": {
          backgroundColor: "#ff9320",
          color: "#ffffff",
        },
        error: { ...DEFAULT },
      };

      return STYLES[item] || DEFAULT;
    },
    goToPage(name) {
      this.$router.push({ name });
    },
    resetConnectionToRename() {
      this.connectionToUpdate = null;
      this.dialogRename = false;
    },
    async confirmUpdateNameConnection() {
      this.loadingRename = true;
      try {
        const req = await this.http.post(`conversionDataConfiguration/rename`, {
          id: this.connectionToUpdate.id,
          name: this.connectionToUpdate.name,
        });
        if (req) {
          this.$toast.success("Conexão atualizada com sucesso!");
          this.getConfigurationList();
          this.loadingRename = false;
          this.resetConnectionToRename();
        }
        this.loadingRename = false;
        this.connectionToUpdate = null;
      } catch (error) {
        this.$toast.error("Erro ao renomear conexão");
        this.loadingRename = false;
      }
    },
    formateDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getConfigurationList() {
      try {
        const URL = this.currentUrl
          ? this.currentUrl
          : "conversionDataConfiguration/list";
        const req = await this.http.get(URL);
        this.recent = req;
        this.showInformations = true;
      } catch (error) {
        this.$toast.error("Erro ao listar configurações");
      }
    },
    async sortTable(nameToSort) {
      this.sortDesc = !this.sortDesc;
      try {
        const ORDER = this.sortDesc ? "ASC" : "DESC";
        const URL = `conversionDataConfiguration/list?sort=${nameToSort}&order=${ORDER}`;
        this.currentUrl = URL;
        const response = await this.http.get(URL);
        if (response) {
          this.recent = response;
          if (this.searchConnection) {
            this.filtered = this.getConnectionsByName(this.searchConnection);
          }
        }
      } catch (error) {
        this.$toast.error("Erro ao ordenar dados");
      }
    },
    async favorite(item) {
      let fav = item.favorite;
      if (fav == null || fav == undefined) {
        fav = false;
        try {
          await this.http.post("conversionDataConfiguration/toggleFavorite", {
            id: item.id,
            favorite: fav,
          });

          this.getConfigurationList();
        } catch (error) {
          this.$toast.error("Erro ao realizar ação");
        }
      } else {
        fav = !fav;
        try {
          await this.http.post("conversionDataConfiguration/toggleFavorite", {
            id: item.id,
            favorite: fav,
          });

          this.getConfigurationList();
        } catch (error) {
          this.$toast.error("Erro ao realizar ação");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: hidden;
    // overflow-y: auto;
    // @include trackScrollBar;
    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }
    &__body {
      display: flex;
      flex-direction: column;
      gap: $z-s-1;
      height: 90%;
      // overflow-y: auto;
      // @include trackScrollBar;

      .table-wrapper {
        height: 400px !important;
        overflow-y: auto;
        @include trackScrollBar;

        .no-data-table {
          text-align: center;
        }

        @media (min-width: 1400px) {
          height: auto !important;
          max-height: 70vh !important;
        }
      }

      // NOVAS TABELAS ADICIONADAS
      @media (min-width: 750px) {
        ::v-deep .v-data-table {
          table {
            th {
              &:not(:first-child) {
                text-align: center !important;
              }
              &:last-child {
                text-align: right !important;
              }
            }
            td {
              &:not(:first-child) {
                text-align: center !important;
                & > div,
                span {
                  margin: 0 auto !important;
                }
              }
              &:last-child {
                text-align: right !important;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 850px) {
    height: auto;
  }
  .badge {
    // border: 1px solid grey;
    padding: 0 0.45rem;
    border-radius: 50px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    max-width: 58%;

    @media (max-width: 650px) {
      max-width: 100%;
    }
    &:hover {
      // background: rgb(236, 236, 236);
    }
  }
}

.delete-confirmation {
  padding: 1rem;
  &__title {
    text-align: center;
    font-weight: 500;
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}
.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
